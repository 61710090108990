import Head from "next/head";
import { useRouter } from "next/router";
import { Segmented } from "antd";
import { useState } from "react";
import Search from "@/public/icons/Search.svg";
import { Input } from "antd";
import { useTranslation } from "next-i18next";

export default function LayoutBlog({
  children,
  seoTitle,
  seoDescription,
  categories,
  onSearch,
}) {
  const router = useRouter();
  const [selectedCategory, setSelectedCategory] = useState(
    Number(router.query.id) || "all"
  );
  const handleChangeCategory = (value) => {
    if (value === "all" && router.pathname !== "/blog") {
      router.push(`/blog`);
    } else {
      const selectedCategory = categories.find((c) => c.value === value);
      router.push(`/blog/category/${selectedCategory.slug}?id=${value}`);
    }
    setSelectedCategory(value);
  };
  const { t } = useTranslation("blog");
  const [valueInput, setValueInput] = useState("");
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSearch(valueInput);
    }
  };

  const handleBlur = () => {
    onSearch(valueInput);
  };

  return (
    <main className="layout-blog">
      <Head>
        <title>{seoTitle || ""}</title>
        <meta name="title" content={seoTitle || ""} />
        <meta name="description" content={seoDescription || ""} />

        <meta property="og:title" content={seoTitle || ""} />
        <meta property="og:description" content={seoDescription || ""} />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}${
            useRouter().locale === "vi" ? "/vi" : ""
          }${useRouter().asPath}`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />

        <meta name="twitter:title" content={seoTitle || ""} />
        <meta name="twitter:description" content={seoDescription || ""} />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />
      </Head>
      <section className="blog-banner">
        <div className="pt-24 pb-12 border-solid border border-t-0 border-l-0 border-r-0 border-[#EAECF0] lg:py-12 container-fuild">
          <div className="content max-w-[780px] mx-auto mb-16">
            <p className="mb-3 font-semibold text-center text-md text-cystack-700">
              {t("latest_news")}
            </p>
            <h1 className="mb-6 font-semibold text-center text-display-lg md:text-display-md text-new-primary-900">
              {t("banner.title")}
            </h1>
            <p className="text-xl text-center md:text-md text-tertiary-600">
              {t("banner.desc")}
            </p>
            <div className="relative flex items-center justify-center gap-2 mt-12">
              <div className="max-w-[340px] w-full relative">
                <Input
                  value={valueInput}
                  className="w-full"
                  placeholder={"Search anything..."}
                  prefix={<Search />}
                  onChange={(e) => setValueInput(e.target.value)}
                  onKeyUp={handleKeyUp}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-center my-12">
        <Segmented
          defaultValue={selectedCategory}
          size="large"
          options={categories}
          onChange={(value) => {
            handleChangeCategory(value);
          }}
        />
      </div>
      <main>{children}</main>
    </main>
  );
}

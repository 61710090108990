const parseRoute = (type) => {
  switch (type) {
    case 'blockchain_protocol_audit':
      return ''

    case 'cloud_security_audit':
      return 'cloud-security-audit'

    case 'data_loss_prevention':
      return 'data-loss-prevention'

    case 'devsecops':
      return 'devsecops'

    case 'incident_response':
      return 'incident-response'

    case 'internal_network_audit':
      return 'internal-network-audit'

    case 'managed_bug_bounty':
      return 'managed-bug-bounty'

    case 'managed_security_service':
      return 'managed-security-service'

    case 'on_chain_monitoring':
      return 'safechain-onchain-monitoring'

    case 'penetration_testing':
      return 'penetration-testing'

    case 'performance_testing':
      return 'performance-testing'

    case 'security_monitoring':
      return 'security-monitoring'

    case 'security_training':
      return 'security-training'

    case 'smart_contract_audit':
      return 'smart-contract-audit'

    case 'vulnerability_assessment':
      return 'vulnerability-assessment'

    case 'vulnerability_management':
      return 'vulnerability-management'

    case 'other':
      return ''

    default:
      return ''
  }
}

import * as cheerio from "cheerio";

const convertPost = (listPost, listCategory = null, users = []) => {
  const results = [];
  listPost.forEach(async (post) => {
    let featuredImage = "";
    try {
      featuredImage = post._embedded["wp:featuredmedia"]["0"].source_url;
    } catch (error) {}
    if (!featuredImage) {
      const $ = cheerio.load(post.content.rendered);
      const images = $("img").attr("src");
      if (images) {
        featuredImage = images.replace(/-[0-9]+x[0-9]+/g, "");
      }
    }
    const $_ = cheerio.load(post.excerpt.rendered);
    const desc = $_("p").text();
    const $_content = cheerio.load(post.content.rendered);
    const readingTime = $_content(".rt-reading-time .rt-time").text() || "";
    const readingUnit =
      $_content(".rt-reading-time .rt-label.rt-postfix").text() || "";
      results.push({
      ...post,
      category: listCategory ? listCategory.find(
        (item) => item.id === post.categories[0]
      ) : {},
      featured_image: featuredImage,
      user: users.find((author) => author.id === post.author) || null,
      desc,
      readingTime,
      readingUnit: readingUnit === "minutes" ? "min" : readingUnit,
    });
  });
  return results
}

export { parseRoute, convertPost }
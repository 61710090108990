import ArrowUpRight from "@/public/icons/arrow-up-right.svg";
import Link from "next/link";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { decode } from "html-entities";

export default function CardBlog({ blog, category, isHighlight = false }) {
  const router = useRouter();
  const { t } = useTranslation("blog");

  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);
  const formatDate = (value) => {
    return router.locale === "en"
      ? dayjs(value).format("LL").replace(",", "")
      : dayjs(value).format("DD/MM/YYYY");
  };

  return (
    <article className="font-inter">
      <div className="thumbnal w-full aspect-video max-h-[600px] rounded-2xl overflow-hidden mb-5 border border-solid border-[#E4E7EC]">
        <img
          src={blog?.featured_image}
          alt="Locker.io blog"
          className="object-cover w-full h-full"
        />
      </div>
      <div
        className={`${
          isHighlight ? "text-sm" : "text-xs"
        } flex items-center gap-2 pr-[10px] pl-1 py-1 border border-solid rounded-full w-fit border-cystack-200 bg-[#EEF4FF] mb-3`}
      >
        <span className="inline-block bg-white border border-solid rounded-full border-cystack-200 text-cystack-700 font-medium py-[2px] px-2">
          {decode(category?.name)}
        </span>
        <span className="font-medium text-cystack-700">
          {blog?.readingTime || ""} {blog?.readingUnit} read
        </span>
      </div>
      <div className="flex items-center gap-4 mb-6">
        <Link href={`/blog/${blog.slug}`}>
          <h4
            className={`${
              isHighlight
                ? "text-display-md lg:text-display-sm"
                : "text-xl lg:text-md"
            } hover:underline font-semibold text-[#101828] line-clamp-2 cursor-pointer`}
          >
            {decode(blog?.title?.rendered) || ""}
          </h4>
        </Link>
        <ArrowUpRight className="flex-shrink-0 w-5" />
      </div>
      <div className="flex items-center gap-3">
        <div className="flex items-center gap-1">
          <img
            className="w-10 h-10 rounded-full"
            src={
              blog?.user?.avatar_urls[48] ||
              blog?.user?.avatar_urls[24] ||
              blog?.user?.avatar_urls[96] ||
              "/images/default-avatar.svg"
            }
            alt="Author's avatar"
          />
        </div>
        <div className="flex flex-col text-[14px] leading-5">
          <span className="text-[#101828] font-semibold">
            {blog?.user?.name ? `@${blog?.user?.name}` : "@CyStack Editor"}
          </span>
          <span className="text-[#475467]">{formatDate(blog.date)}</span>
        </div>
      </div>
    </article>
  );
}
